<template>
  <div class="fill-height fill-width">
    <PublicSessionVideo v-bind="$props" />
  </div>
</template>

<script>
import PublicSessionVideo from '@components/public/PublicSessionVideo.vue'

export default {
  page: {
    title: 'Assistenza',
  },
  components: {
    PublicSessionVideo,
  },
  props: {
    project: {
      type: String,
      default: null,
    },
    sid: {
      type: String,
      default: null,
    },
  },
}
</script>
